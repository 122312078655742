<template>
  <b-card>
    <h4 class="mb-0">
      Edit Data Kehadiran
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="student_id" rules="required">
              <b-form-group label="Siswa" label-for="student_id" :state="errors.length > 0 ? false : null">
                <v-select id="student_id" v-model="dataStudent.student_id" :reduce="(student_id) => student_id.real_id" placeholder="Pilih Siswa"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa" label="name" disabled/>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="{ errors }" name="class_id" rules="required">
              <b-form-group label="Kelas" label-for="class_id" :state="errors.length > 0 ? false : null">
                <v-select id="class_id" v-model="dataStudent.class_id" :reduce="(class_id) => class_id.real_id" placeholder="Pilih Kelas"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass" label="class_desc" disabled/>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <b-form-group label="Tanggal" label-for="date">
              <validation-provider #default="{ errors }" name="date" rules="required">
                <b-form-input id="date" v-model="dataStudent.date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Masuk" label-for="time_in">
              <validation-provider #default="{ errors }" name="time_in" rules="required">
                <b-form-input id="time_in" v-model="dataStudent.time_in" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Masuk" type="time" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Keluar" label-for="time_out">
              <validation-provider #default="{ errors }" name="time_out" rules="required">
                <b-form-input id="time_out" v-model="dataStudent.time_out" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Keluar" type="time" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="type" rules="required">
              <b-form-group label="Tipe Absensi" label-for="type" :state="errors.length > 0 ? false : null">
                <v-select id="type" v-model="dataStudent.type" :reduce="(type) => type.real_id" placeholder="Pilih Tipe Absensi"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listAbsenTipe" label="absent_type_desc" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>                        
            <b-form-group label="Keterangan" label-for="description">
              <validation-provider #default="{ errors }" name="description" rules="required">
                <b-form-input id="description" v-model="dataStudent.description" :state="errors.length > 0 ? false : null"
                  placeholder="Keterangan" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>            
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="info">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    listSiswa: [],
    listAbsenTipe: [],
    listClass:[],
    dataStudent: {
      id: '',
      student_id: '',
      class_id: '',
      date: '',
      time_in: '',
      time_out: '',
      type: '',
      description: '',
    },

  }),
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
    this.getsiswa()
    this.getabsentipe()
    this.getClass()
  },
  methods: {
    async getClass() {
      try {
        const response = await this.$http.get('/masterclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getabsentipe() {
      try {
        const response = await this.$http.get('/absenttypes')
        this.listAbsenTipe = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/kehadirans/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.id = data.id
      this.dataStudent.student_id = data.student_id
      this.dataStudent.class_id = data.class_id
      this.dataStudent.date = data.date.substr(0, 10)
      this.dataStudent.time_in = data.time_in
      this.dataStudent.time_out = data.time_out
      this.dataStudent.type = data.type
      this.dataStudent.description = data.description
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/kehadirans/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'absensi-data-kehadiran' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
